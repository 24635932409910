/* global Component */
class contactTukson extends Component {

    static  name() {
        return "ContactPage";
    }

    static componentName() {
        return "ContactPage";
    }


    getTemplate() {
        return `<div class="static-page contact">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="row section-content">
                        <div class="col-12 col-md-8">
                            <div class="title">
                                <p>Dejanos tu consulta, respondemos a diario!</p>
                            </div>
                            <form id="contact-form" class="row justify-content-center" action="/leads/leads_process_api?uuid=7941963b-8156-44e3-b361-2b576c18ea34" method="post">
                                <input type="hidden" name="LeadSource" value="00001"/>
                                <div class="col-12 col-md-6 form-group">
                                    <input id="nombre" name="Nombre" type="text" class="custom-input" placeholder="Nombre*" required>
                                </div>
                                <div class="col-12 col-md-6 form-group">
                                    <input id="email" name="Email" type="text" class="custom-input" placeholder="E-mail*" required>
                                </div>
                                <div class="col-12 form-group">
                                    <input id="asunto" name="Asunto" type="text" class="custom-input" placeholder="Asunto" required>
                                </div>
                                <div class="col-12 form-group">
                                    <textarea id="mensaje" name="Mensaje" class="custom-input" placeholder="Mensaje" rows="4" required>
                                    </textarea>
                                </div>
                                <div class="text-center mt-2">
                                    <button type="submit" class="send-button">
                                        <p>Enviar Mensaje</p><i class="far fa-arrow-alt-circle-right fa-2x"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="title">
                                <p>Información de Contacto</p>
                            </div>
                            <div class="contact-info">
                                <p>
                                    <i class="fa-solid fa-shop"></i>
                                    Argerich 419, Flores
                                </p>
                                <p>
                                    <i class="fa-brands fa-whatsapp"></i>
                                    +54 9 11 7612-5272
                                </p>
                                <p>
                                    <i class="fa-regular fa-envelope"></i>
                                    ventas@tuksonargentina.com.ar
                                </p>
                            </div>
                            <div class="title">
                                <p>Información de Contacto</p>
                            </div>
                            <div class="contact-info">
                                <p>
                                    <i class="fa-regular fa-clock"></i>
                                    Lunes a Viernes - 9 a 17hs
                                </p>
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

contactTukson.registerComponent();
